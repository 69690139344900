import * as React from 'react';
import {StaticImage} from "gatsby-plugin-image";

const Windows = () => {
    return (<div className="flex w-full h-full justify-center items-center self-center">
        <a href="https://qub.link/timetable-windows-new" className="w-3/4 md:w-1/2"
           aria-label="microsoft store download"><StaticImage
            src="../../images/microsoft_store.svg"
            alt="microsoft store download"
            placeholder="tracedSVG"
            layout="constrained"
        /></a>

    </div>)
}

export default Windows;
