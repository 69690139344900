import * as React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import Alert from "../alert";
import {useTranslation} from "react-i18next";

const Osx = () => {
    const {t} = useTranslation("downloadOsx");
    return (<div className="flex gap-5 flex-col w-full min-h-full justify-center items-center self-center text-xl">
        <Alert>{t("alert")}</Alert>
        <p className="text-white text-center w-full md:w-3/4 mx-auto ">{t("step1")}</p>
        <div className="flex justify-center items-center w-full md:w-3/4 lg:w-1/2 mx-auto border-2 border-white">
            <StaticImage
                src="../../images/tutorial/osx/1.png"
                alt="step 1 screenshot"
                placeholder="blurred"
                layout="constrained"
            />
        </div>
        <hr className="h-2 w-full md:w-3/4"/>
        <p className="text-white text-center w-full md:w-3/4 mx-auto ">{t("step2")}</p>
        <div className="flex justify-center items-center w-full md:w-3/4 lg:w-1/2 mx-auto border-2 border-white">
            <StaticImage
                src="../../images/tutorial/osx/2.png"
                alt="step 2 screenshot"
                placeholder="blurred"
                layout="constrained"
            />
        </div>
        <hr className="h-2 w-full md:w-3/4"/>
        <p className="text-white text-center w-full md:w-3/4 mx-auto ">{t("step3")}</p>
        <div className="flex justify-center items-center w-full md:w-3/4 lg:w-1/2 mx-auto border-2 border-white">
            <StaticImage
                src="../../images/tutorial/osx/3.png"
                alt="step 3 screenshot"
                placeholder="blurred"
                layout="constrained"
            />
        </div>
    </div>)
}

export default Osx;
