import * as React from 'react';
import {StaticImage} from "gatsby-plugin-image";

const Android = () => {
    return (<div className="flex w-full h-full justify-center items-center self-center">
        <a href="https://qub.link/timetable-android" className="w-3/4 md:w-1/2"
           aria-label="google play download"><StaticImage
            src="../../images/google_play.svg"
            alt="google play download"
            placeholder="tracedSVG"
            layout="constrained"
        /></a>

    </div>)
}

export default Android;
