import * as React from "react";
import { IconType } from "react-icons";
import { MdErrorOutline } from "react-icons/md";

type Props = {
  icon?: IconType;
  color?: string;
};

const Alert: React.FC<Props> = ({ children, color = "bg-red", icon = MdErrorOutline, ...rest }) => (
  <>
    <div
      {...rest}
      className={`flex items-center gap-3 text-lg text-white p-4 pr-12 border-0 rounded-lg relative mb-4 ${color} transition-all duration-300`}
    >
      {React.createElement(icon, { className: "text-2xl w-10" })}
      {children}
    </div>
  </>
);
export default Alert;
