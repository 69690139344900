import * as React from "react";
import { useEffect, useState } from "react";
import { MdHome } from "react-icons/md";
import { PlatformDetection } from "@ephox/sand";
import { SiAndroid, SiApple, SiIos, SiWindows } from "react-icons/si";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import Seo from "../components/seo";
import NavBar from "../components/navBar";
import Android from "../components/download/android";
import Windows from "../components/download/windows";
import Ios from "../components/download/ios";
import Osx from "../components/download/osx";

const Options = [
  {
    icon: SiAndroid,
    title: "Android",
    content: Android,
  },
  {
    icon: SiWindows,
    title: "Windows",
    content: Windows,
  },
  {
    icon: SiIos,
    title: "iOS",
    content: Ios,
  },
  {
    icon: SiApple,
    title: "OSX",
    content: Osx,
  },
];

type DownloadSelection =
  | "Android"
  | "Windows"
  | "iOS"
  | "OSX"
  | "Linux"
  | "Solaris"
  | "FreeBSD"
  | "ChromeOS"
  | undefined;

const Download: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [selected, setSelected] = useState(Options[0]);
  const { t } = useTranslation();

  useEffect(() => {
    const platform = PlatformDetection.detect();
    const result = Options.find((e) => e.title === platform.os.current);
    if (result != null) setSelected(result);
  }, []);

  return (
    <div className="flex flex-col h-full">
      <Seo title={t("downloadApps")} description={t("downloadDescription")} />
      <NavBar title={t("downloadApps")} navButton={{ icon: MdHome, location: "/" }} />
      <div className="h-full w-full overflow-y-auto">
        <div className="flex flex-col min-h-full p-5">
          <div className="flex flex-wrap justify-evenly items-center mb-5">
            {Options.map((value, index, array) => (
              <>
                <button
                  type="button"
                  key={value.title}
                  className={`flex justify-center items-center gap-3 transition duration-500 ease-in-out transform hover:scale-110 text-xl align-middle ${
                    selected.title === value.title ? "text-orange" : "text-white"
                  }`}
                  onClick={() => setSelected(value)}
                >
                  {React.createElement(value.icon, { className: "text-2xl" })}
                  {isMobile ? null : <span>{value.title}</span>}
                </button>
                {index !== array.length - 1 ? <div className="border-r-2 border-white h-10 w-0" /> : null}
              </>
            ))}
          </div>
          <div className="flex flex-grow">{React.createElement(selected.content)}</div>
        </div>
      </div>
    </div>
  );
};

export default Download;
